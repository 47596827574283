<template>
  <div>
    <div class="color-picker w-[100px]" />
    <!-- <p>{{ syncColor }}</p> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useVModel } from '@vueuse/core'

// One of the following themes
import '@simonwep/pickr/dist/themes/classic.min.css' // 'classic' theme
import '@simonwep/pickr/dist/themes/monolith.min.css' // 'monolith' theme
import '@simonwep/pickr/dist/themes/nano.min.css' // 'nano' theme

// Modern or es5 bundle (pay attention to the note below!)
import Pickr from '@simonwep/pickr'

export default defineComponent({
  name: 'ColorPicker',
  props: {
    value: String,
    opacity: Boolean,
  },
  setup (props, { emit }) {
    const syncColor = useVModel(props, 'value', emit)
    const test = ref('')
    const changeColor = (color, sorce, instance) => {
      syncColor.value = color
    }

    onMounted(() => {
      const picker = Pickr.create({
        el: '.color-picker',
        theme: 'monolith', // or 'monolith', or 'nano',
        comparison: false,
        default: props.value,
        components: {
          // Main components
          preview: true,
          opacity: props.opacity,
          hue: true,

          // Input / output Options
          interaction: {
            hex: true,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            // save: true,
          },
        },
        i18n: {
          'btn:save': '確認',
        },
      })

      picker.on('change', (color, source, instance) => {
        changeColor(color.toHEXA().toString())
        emit('change', color)
      })
    })
    return { syncColor, test }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .pickr {
  @apply flex flex-col justify-center items-center;
}

::v-deep .pickr .pcr-button{
    @apply w-[80px] h-[28px] rounded-[4px];
}
</style>
