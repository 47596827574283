<template>
  <div class="member-center-color-theme-preview">
    <div class="home relative">
      <SVGIcon
        class="test"
        icon="memberCenter-home_secondary"
        width="100px"
        height="220px"
        :fill="secondary"
      />
      <SVGIcon class="test" icon="memberCenter-home_primary" width="100" height="220" :fill="primary" />
      <img class="test" src="@/assets/svgIcon/memberCenter/home-mockup.png" style="width: 100px">
    </div>

    <div class="home relative">
      <SVGIcon class="test" icon="memberCenter-coupon_primary" width="100px" height="220px" :fill="primary" />
      <SVGIcon
        class="test"
        icon="memberCenter-coupon_secondary"
        width="100px"
        height="220px"
        :fill="secondary"
      />
      <img class="test" src="@/assets/svgIcon/memberCenter/coupon-mockup.png" style="width: 100px">
    </div>

    <div class="home relative">
      <img class="test" src="@/assets/svgIcon/memberCenter/list-mockup.png" style="width: 100px">
      <SVGIcon class="test" icon="memberCenter-list_primary" width="100px" height="220px" :fill="primary" />
    </div>
    <div class="home relative">
      <img class="test" src="@/assets/svgIcon/memberCenter/record-mockup.png" style="width: 100px">
      <SVGIcon class="test" icon="memberCenter-record_primary" width="100px" height="220px" :fill="primary" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MemberCenterColorThemePreview',
  props: {
    primary: String,
    secondary: String,
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.member-center-color-theme-preview {
    @apply flex items-center gap-[20px];
}
.home {
    @apply bg-white w-[100px] h-[220px];
}
.test {
    @apply absolute top-0 left-0 right-0 bottom-0 m-auto;
}
</style>
